<template>
  <div class="table_wrapper">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      :search="search"
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:[`item.c7`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              :to="'/cms/edit/' + item.item.id"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>CMS</v-toolbar-title>
          <v-btn
            small
            color="error"
            v-on:click="deleteSelected"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Delete Selected ({{ selected.length }})
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            disabled
          ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import moment from "moment";
export default {
  name: "Cms",
  data() {
    return {
      selected: [],
      moment: moment,
      actionLock: false,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c1", sortable: false },
        { text: "Title", value: "c2", sortable: false },
        { text: "Slug", value: "c3", sortable: false },
        { text: "Lang", value: "c4", sortable: false },
        { text: "Created at", value: "c5", sortable: false },
        { text: "Updated at", value: "c6", sortable: false },
        { text: "action", value: "c7", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.activeTab);
      },
      deep: true,
    },
  },
  methods: {
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },

    parseDateTime: function(date) {
      let dta = new Date(date).getTime();
      return this.moment(dta).fromNow();
    },
    deleteSelected: function() {
      if (this.selected.length == 0) {
        return false;
      } else {
        const selectedMap = this.selected.map(function(val, index) {
          return val.id;
        });

        axios
          .post("https://gowawe.com/api/admin/quotation/bulkdelete", {
            id: selectedMap,
            api_token: this.token,
          })
          .then((res) => {
            this.getRefreshData();
          })
          .catch((err) => {
            console.log(err);
            this.getRefreshData();
          });
      }
    },
    getRefreshData: function() {
      this.selected = [];
      this.getDataFromApi(this.activeTab);
    },
    getDataFromApi(a) {
      this.activeTab = a;
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item, index) => {
          const key = {
            c1: item.id,
            c2: item.title,
            c3: item.slug,
            c4: item.lang_id,
            c5: this.parseDateTime(item.created_at),
            c6: this.parseDateTime(item.updated_at),
            id: item.id,
            item: item,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
        this.actionLock = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          resolve({
            itemsR,
            total,
          });
        });
      });
    },
    async getDesserts() {
      const fields = [];
      const sort = [];

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
            "page/filter?page=" +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token,
          {
            fields,
            sort: sort,
            type: "",
          }
        );
        return response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
